import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Image from '../components/Image';
import Subfooter from '../components/Subfooter';
import Icon from '../components/Icon';
import OutboundLink from '../components/OutboundLink';

const AboutPage = () => (
  <Layout title="About the Program">
    <Section className="about-mission">
      <Container>
        <h1 className="h1">About</h1>
        <Image filename="about-image-hero.jpg" className="about__hero-image" />
      </Container>
    </Section>
    <Section>
      <Container>
        <Row>
          <Column size={6}>
            <Image filename="about-image-inspiringstudents.jpg" />
          </Column>
          <Column size={6}>
            <h3 className="h3">
              Inspiring student changemakers through health equity education
            </h3>
            <p>
              Everyone on Earth deserves access to a healthier life, and every
              young person has the power to help create a more equitable future
              for their world—starting with how they approach today. Takeda and
              Discovery Education are partnering to help equip a new generation
              of health equity advocates through standards-aligned educational
              resources focusing on health equity, health literacy, and access
              to health care.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="about__floating-graphic-holder">
      <Image
        filename="about-image-molecule.png"
        className="about__floating-graphic"
      />
    </Section>
    <Section className="about-partners">
      <Container>
        <h3 className="h3">About the Partners</h3>
        <Row className="about-partner">
          <Column size={5}>
            <Image
              filename="global-logo-takeda.png"
              className="about-partner-logo"
              alt="Takeda Logo"
            />
          </Column>
          <Column size={7}>
            <p>
              Takeda is focused on creating better health for people and a
              brighter future for the world. We aim to discover and deliver
              life-transforming treatments in our core therapeutic and business
              areas, including gastrointestinal and inflammation, rare diseases,
              plasma-derived therapies, oncology, neuroscience and vaccines.
              Together with our partners, we aim to improve the patient
              experience and advance a new frontier of treatment options through
              our dynamic and diverse pipeline. As a leading values-based,
              R&D-driven biopharmaceutical company headquartered in Japan, we
              are guided by our commitment to patients, our people and the
              planet. Our employees in approximately 80 countries and regions
              are driven by our purpose and are grounded in the values that have
              defined us for more than two centuries.
            </p>
            <OutboundLink
              to="https://www.takeda.com/"
              className="text-bold text-upper"
            >
              <Icon name="externallink" marginRight />
              View Takeda.com
            </OutboundLink>
          </Column>
        </Row>
        <Row className="about-partner">
          <Column size={5}>
            <Image
              filename="de-logo-positive.png"
              className="about-partner-logo"
              alt="Discovery education logo"
            />
          </Column>
          <Column size={7}>
            <p>
              Discovery Education is the worldwide edtech leader whose
              state-of-the-art digital platform supports learning wherever it
              takes place. Through its award-winning multimedia content,
              instructional supports, and innovative classroom tools, Discovery
              Education helps educators deliver equitable learning experiences
              engaging all students and supporting higher academic achievement
              on a global scale. Discovery Education serves approximately 4.5
              million educators and 45 million students worldwide, and its
              resources are accessed in over 100 countries and territories.
              Inspired by the global media company Warner Bros. Discovery, Inc.,
              Discovery Education partners with districts, states, and trusted
              organizations to empower teachers with leading edtech solutions
              that support the success of all learners.
            </p>
            <OutboundLink
              to="https://www.discoveryeducation.com/"
              className="text-bold text-upper"
            >
              <Icon name="externallink" marginRight />
              View DiscoveryEducation.com
            </OutboundLink>
          </Column>
        </Row>
      </Container>
    </Section>
    <Subfooter
      className="subfooter__quote-about"
      footerImage="about-image-QuoteBox@2x.png"
      footerText="Our job as educators is to introduce curriculum that acts as windows, mirrors, and sliding glass doors for our students. These resources give your students the opportunity to see the bigger picture, encourage self-reflection, and action."
      footerSourceOne={'Pedro Delgado'}
      footerSourceTwo={'Middle & High School Teacher,'}
      footerSourceThree={'El Paso, Texas'}
    />
  </Layout>
);

export default AboutPage;
